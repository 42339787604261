import { createApp } from 'vue'
import App from './App.vue'
import { router } from '@/services/router'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { Tell } from '@/services/Tell'
import { AuthService } from '@/contracts/AuthService'
import { EntraAuthService } from '@/services/EntraAuthService'
import { CommercialApi } from '@/services/CommercialApi'
import { EntraAuthConfig as entraAuthConfig } from '@/services/EntraAuthConfig'
import { CommercialApiConfig as commercialApiConfig } from '@/services/CommercialApiConfig'
import { SignalRMessageService } from '@/services/SignalRMessageService'
import telemetry from '@/services/telemetry'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import './assets/styles/main.scss'
import 'vuetify/styles'
import "@mdi/font/css/materialdesignicons.css";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import { GoogleMap } from 'vue3-google-map';
import { useAppStore } from '@/stores/app.store';
import colors from 'vuetify/util/colors'
import { AuthenticatedFetch } from './services/AuthenticatedFetch'
// services 

// stores 

// styles

export function startApp(authService: AuthService) {
	//const data = await mockService.getData();
	const app = createApp(App);
	const pinia = createPinia();
	
	pinia.use(piniaPluginPersistedstate);
	
	app.use(router);
	app.use(pinia);
	
	// Detect system preference for dark mode
	const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
	let defaultTheme = prefersDarkMode ? 'dark' : 'light';
	
	// Check the app store for current settings
	const appStore = useAppStore();
	
	// if the app store has settings; use those
	if(appStore.getTheme != null)
	{
		if(appStore.getTheme != 'system')
		{
			defaultTheme = appStore.getTheme;
		}
	}
	else
	{
		// save this to the store
		if(prefersDarkMode)
		{
			appStore.setTheme('system');
		}
		else
		{
			appStore.setTheme(defaultTheme);
		}
	}
	
	const vuetify = createVuetify({
		components,
		directives,
		icons: {
			defaultSet: 'mdi',
			aliases,
			sets: {
				mdi,
			},
		},
		theme: {
			defaultTheme: defaultTheme,
			themes: {
				light: {
					dark: false,
					colors: {
						primaryMoe: '#4d7e5a',
						primary: '#245e36',
						secondary: '#d67c3b',
						accent: '#4d7e5a',
						error: '#c94241',
						info: '#6099ac',
						success: '#4d7e5a',
						warning: '#d67c3b',
						buttonColor: '#245e36',
						warningLabel: colors.yellow.accent2,
						warningAddress: colors.pink.lighten3
					},
				},
				dark: {
					dark: true,
					colors: {
						primary: '#4d7e5a',
						secondary: '#6d6e64',
						accent: '#80b9cd',
						error: '#c94241',
						info: '#a1a096',
						success: '#4d7e5a',
						warning: '#d67c3b',
					},
				},
			}
		}
	});
	
	app.use(vuetify);
	
	app.use(VueGoogleMaps, {
		load: {
			key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
			libraries: ['places'],
		}
	});
	
	app.component('GoogleMap', GoogleMap);
		
	app.provide('auth', authService);
	app.provide('telemetry', telemetry);
	app.provide('tell', new Tell());
	
	const commercialApi = new CommercialApi(commercialApiConfig, new AuthenticatedFetch(authService));
	const signalRService = new SignalRMessageService(commercialApi, telemetry);
	
	app.provide('policyService', commercialApi);
	app.provide('rateService', commercialApi);
	app.provide('configService', commercialApi);
	app.provide('messageService', signalRService);
	
	app.mount('#app');
}

if( process.env.VUE_APP_IS_TESTING !== 'true') {
	console.log('Starting app with real services', process.env.VUE_APP_IS_TESTING);
	//start regularly
	startApp(new EntraAuthService(entraAuthConfig));
}
// startApp(new EntraAuthService(entraAuthConfig));