import { ConfigService } from "@/contracts/ConfigService";
import { ApplicationInsights, SeverityLevel, IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

export class DocsGeneratedMessage {
	PolicyNumber: string;
}

export class SignalRMessageService {
	private connection: signalR.HubConnection | null = null;
	private configService: ConfigService;
	private telemetry: ApplicationInsights;

	constructor(configService: ConfigService, telemetry: ApplicationInsights) { 
		this.configService = configService;
		this.telemetry = telemetry;
	}

	// Start the SignalR hub connection
	public async start(hubName: string): Promise<void> {
		if (!this.connection) {
			const connectionResult = await this.configService.getMessageClientConnection(hubName);

			if(connectionResult.success) {
				this.connection = new HubConnectionBuilder()
					.withUrl(connectionResult.connectionInfo.url, {
						accessTokenFactory: () => connectionResult.connectionInfo.accessToken
					}) // Replace with your Azure SignalR endpoint
					.configureLogging(LogLevel.Warning)
					.build();

				try {
					await this.connection.start();
					console.log("SignalR Connected.");
				} catch (err) {					
					const exceptionTelemetry: IExceptionTelemetry = {
						exception: new Error("Error while starting SignalR connection"),
						severityLevel: SeverityLevel.Error,
						properties: { connectionResult: connectionResult }
					};

					this.telemetry.trackException(exceptionTelemetry);

					setTimeout(() => this.start(hubName), 5000); // Retry on failure
				}
			}
			else
			{
				const exceptionTelemetry: IExceptionTelemetry = {
					exception: new Error("Error while negotiating for SignalR config"),
					severityLevel: SeverityLevel.Error,
					properties: { connectionResult: connectionResult }
				};

				this.telemetry.trackException(exceptionTelemetry);

				setTimeout(() => this.start(hubName), 5000); // Retry on failure
			}
		}
	}

	// Listen for messages from the server
	public onMessage<T>(methodName: string, callback: (data: T) => void): void {
		if (this.connection) {
			this.connection.on(methodName, callback);
		}
	}

	// Send a message to the server
	public async sendMessage<T>(methodName: string, data: T): Promise<void> {
		if (this.connection) {
			try {
				await this.connection.invoke(methodName, data);
				console.log(`Message sent to ${methodName}:`, data);
			} catch (err) {
				console.error("Error while sending message:", err);
			}
		}
	}

	// Stop the SignalR connection
	public async stop(): Promise<void> {
		if (this.connection) {
			await this.connection.stop();
			console.log("SignalR Disconnected.");
			this.connection = null;
		}
	}
}
