import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../assets/img/brokenpolicy.jpg'


const _hoisted_1 = { class: "text-center pa-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WaitDialog = _resolveComponent("WaitDialog")!
  const _component_PolicySummary = _resolveComponent("PolicySummary")!
  const _component_m_btn = _resolveComponent("m-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_WaitDialog, {
        isActive: $setup.isWaiting,
        title: "Fixing policy..."
      }, null, 8 /* PROPS */, ["isActive"]),
      _createVNode(_component_PolicySummary, { policyNumber: $setup.policyNumber }, null, 8 /* PROPS */, ["policyNumber"]),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "container text-start" }, [
        _createElementVNode("p", null, "This function will correct some known issues with policy data."),
        _createElementVNode("p", null, [
          _createTextVNode("This feature is only to be used if this policy module is missing data and looks like this: "),
          _createElementVNode("img", {
            src: _imports_0,
            alt: "Broken Policy"
          })
        ]),
        _createElementVNode("p", null, [
          _createTextVNode("Please note, once you take this action, "),
          _createElementVNode("strong", null, "you must delete this pending module.")
        ]),
        _createElementVNode("p", null, "Once you delete this module, you may create a new renewal module from the existing term.")
      ], -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_dialog, {
          modelValue: $setup.dialog,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.dialog) = $event)),
          "max-width": "400",
          persistent: ""
        }, {
          activator: _withCtx(({ props: activatorProps }) => [
            _createVNode(_component_m_btn, _mergeProps(activatorProps, { color: "primary" }), {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" Fix my policy ")
              ])),
              _: 2 /* DYNAMIC */
            }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_card, {
              "prepend-icon": "mdi-map-marker",
              text: "Once you take this action, you will be required to delete your pending module.",
              title: "Are you sure?"
            }, {
              actions: _withCtx(() => [
                _createVNode(_component_v_spacer),
                _createVNode(_component_m_btn, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.dialog = false)),
                  text: "Nevermind",
                  color: "primary"
                }),
                _createVNode(_component_m_btn, {
                  onClick: $setup.fixMyPolicy,
                  text: "Let's go!"
                }, null, 8 /* PROPS */, ["onClick"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}