import { defineStore } from 'pinia';

interface AppState {
	theme: string | null;
}

export const useAppStore = defineStore({
	id: 'appStore',
	state: (): AppState => {
		return {
			theme: null,
		};
	},
	// this tells the store to persist to local storage
	persist: true,
	getters: {
		// getters are reactive
		getTheme: (state): string | null => state.theme,
	},
	actions: {
		setTheme(theme: string): void {
			this.theme = theme;
		}
	}
});