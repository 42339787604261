export class MessageClientConnectionInfo {
	/**
	 * A url to have the client connect to an endpoint.
	 */
	url?: string;
 
	/**
	 * An optional access token to go along with the Url.
	 */
	accessToken?: string;
 
	/**
	 * The public ID for the connection.
	 */
	connectionId?: string;
 
	/**
	 * The private ID for the connection.
	 */
	connectionToken?: string;
 
	/**
	 * The minimum value between the version the client sends and the maximum version the server supports.
	 */
	version!: number;
 
	/**
	 * An optional error during the negotiate. If this is not null the other properties on the response can be ignored.
	 */
	error?: string;
 
	constructor(init?: Partial<MessageClientConnectionInfo>) {
		Object.assign(this, init);
	}
 }

 export class GetMessageClientConnectionInfoResponse {

	connectionInfo!: MessageClientConnectionInfo;
	success: boolean;
	message: string;

	constructor(init?: Partial<GetMessageClientConnectionInfoResponse>) {
		Object.assign(this, init);
	}
 }
 