import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "policySummary" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "headers" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "4" }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" Policy Number ")
            ])),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_v_col, { cols: "4" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Status ")
            ])),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "4" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.policyData.policyNumber), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_v_col, { cols: "4" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.policyData.statusCode === "V" ? "Verified" : "Pending"), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}