import { AccountInfo } from "@azure/msal-browser";
import { AuthService } from "@/contracts/AuthService";

/* 
A wrapper for fetch() that adds authentication headers to the request.
*/
export class AuthenticatedFetch {
	private authService: AuthService;

	constructor(authService: AuthService) {
		this.authService = authService;
	}

	async fetch(url: string, options: RequestInit = {}): Promise<Response> {
		const account = await this.authService.getActiveAccount();

		if (!account) {
			throw new Error('Unable to fetch configuration info, no logged in user');
		}

		const authHeaders = {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${account.idToken}`,
			'X-User-Id': account.username
		};

		const mergedHeaders = {
			...(options.headers || {}),
			...authHeaders,
		};

		return fetch(url, {
			...options,
			headers: mergedHeaders,
		});
	}
}
