import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "error-container"
}
const _hoisted_2 = { class: "error-message" }
const _hoisted_3 = { class: "button-container" }
const _hoisted_4 = {
  key: 1,
  class: "container text-start"
}
const _hoisted_5 = {
  key: 0,
  class: "container alert alert-warning"
}
const _hoisted_6 = { class: "container policyInfo p-0" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "insuranceLine" }
const _hoisted_9 = { class: "fw-lighter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WaitDialog = _resolveComponent("WaitDialog")!
  const _component_base_button = _resolveComponent("base-button")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_m_btn = _resolveComponent("m-btn")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { style: {"margin-top":"30px"} }, {
    default: _withCtx(() => [
      _createVNode(_component_WaitDialog, {
        isActive: $setup.isLoading,
        title: "Loading data..."
      }, null, 8 /* PROPS */, ["isActive"]),
      ($setup.errorMessage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createTextVNode(_toDisplayString($setup.errorMessage) + " ", 1 /* TEXT */),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_base_button, {
                  onClick: $setup.dismissError,
                  text: "OK"
                }, null, 8 /* PROPS */, ["onClick"])
              ])
            ])
          ]))
        : _createCommentVNode("v-if", true),
      ($setup.policyData && !$setup.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            ($setup.policyData.transactionType === 'EN')
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Prior to making deductible changes, please rate your endorsement at least once. "))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_v_row, {
                class: "row headers",
                align: "center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "4" }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode(" Policy Number ")
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, { cols: "4" }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" Status ")
                    ])),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, {
                    cols: "2",
                    class: "text-center"
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode(" State ")
                    ])),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_row, { align: "center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "4" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.policyNumber), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, { cols: "4" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.policyData.status === "V" ? "Verified" : "Pending"), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, { cols: "2" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_select, {
                        modelValue: $setup.selectedState,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedState) = $event)),
                        items: $setup.states,
                        variant: "solo-filled",
                        style: {"min-width":"100px","margin-top":"17px"},
                        class: "pa-0"
                      }, null, 8 /* PROPS */, ["modelValue", "items"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, {
                    cols: "2",
                    class: "text-end"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_m_btn, {
                        onClick: $setup.updateDeductibles,
                        text: "Update",
                        disabled: !$setup.policyData.canUpdate,
                        color: "primary"
                      }, null, 8 /* PROPS */, ["onClick", "disabled"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ])
          ]))
        : _createCommentVNode("v-if", true),
      ($setup.policyData && $setup.policyData.canUpdate && !$setup.isLoading)
        ? (_openBlock(), _createBlock(_component_v_container, {
            key: 2,
            class: "container"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.lineItems, (lineItem, index) => {
                return (_openBlock(), _createBlock(_component_v_container, {
                  key: index,
                  class: "pa-0"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lineItem.coverageGroups, (coverage, index) => {
                      return (_openBlock(), _createBlock(_component_v_container, {
                        key: index,
                        class: "text-start pa-0",
                        "data-state": "{{lineItem.state}}",
                        ref_for: true,
                        ref: "lineItemsRef"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_row, {
                            align: "center",
                            class: "text-start pa-0"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, {
                                cols: "4",
                                class: "text-start pa-0"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(coverage.coverageCodeDescription) + " ", 1 /* TEXT */),
                                  (lineItem.coverageCode === 'RVPD')
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                        _createElementVNode("div", _hoisted_8, [
                                          _createElementVNode("div", _hoisted_9, " Line of Business: " + _toDisplayString($setup.getInsuranceLineDescription(lineItem.insuranceLineCode)), 1 /* TEXT */)
                                        ])
                                      ]))
                                    : _createCommentVNode("v-if", true)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */),
                              _createVNode(_component_v_col, { cols: "4" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_select, {
                                    label: "From",
                                    "item-title": "title",
                                    variant: "solo-filled",
                                    items: coverage.fromDeductibles,
                                    modelValue: coverage.selectedFrom,
                                    "onUpdate:modelValue": ($event: any) => ((coverage.selectedFrom) = $event),
                                    clearable: "",
                                    "return-object": ""
                                  }, null, 8 /* PROPS */, ["items", "modelValue", "onUpdate:modelValue"])
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */),
                              _createVNode(_component_v_col, { cols: "4" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_select, {
                                    label: "To",
                                    "item-title": "title",
                                    variant: "solo-filled",
                                    items: coverage.toDeductibles,
                                    modelValue: coverage.selectedTo,
                                    "onUpdate:modelValue": ($event: any) => ((coverage.selectedTo) = $event),
                                    clearable: "",
                                    "return-object": ""
                                  }, null, 8 /* PROPS */, ["items", "modelValue", "onUpdate:modelValue"])
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */),
                          _createVNode(_component_v_row, { class: "includesLine" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_col, {
                                cols: "12",
                                class: "fw-lighter pa-4"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(" Includes: " + _toDisplayString($setup.getBigDescription(coverage.coverageCode)), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }))
}