import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "search-box",
  class: "elevation-1 bg-primary rounded"
}
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = { class: "text-end" }
const _hoisted_4 = { class: "text-end" }
const _hoisted_5 = { style: {"max-width":"300px"} }
const _hoisted_6 = { class: "text-end" }
const _hoisted_7 = {
  id: "mapContainer",
  style: {"width":"auto","height":"auto"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WaitDialog = _resolveComponent("WaitDialog")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_m_btn = _resolveComponent("m-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_Marker = _resolveComponent("Marker")!
  const _component_GoogleMap = _resolveComponent("GoogleMap")!
  const _component_v_textarea = _resolveComponent("v-textarea")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_WaitDialog, {
      isActive: $setup.isWaiting,
      title: "Working"
    }, null, 8 /* PROPS */, ["isActive"]),
    _createVNode(_component_v_navigation_drawer, {
      permanent: "",
      modelValue: $setup.drawer,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.drawer) = $event)),
      rail: $setup.rail,
      elevation: "5",
      onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.rail = !$setup.rail)),
      width: "320",
      color: $setup.drawerColor
    }, {
      append: _withCtx(() => [
        _createVNode(_component_v_container, { fluid: "" }, {
          default: _withCtx(() => [
            (!$setup.rail)
              ? (_openBlock(), _createBlock(_component_v_row, {
                  key: 0,
                  justify: "end"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      icon: "mdi-chevron-left",
                      variant: "text",
                      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.rail = !$setup.rail), ["stop"]))
                    })
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_v_row, { justify: "center" }, {
              default: _withCtx(() => [
                ($setup.rail)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      icon: "mdi-chevron-right",
                      variant: "text",
                      onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => ($setup.rail = !$setup.rail), ["stop"]))
                    }))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_list, {
          density: "compact",
          class: "mt-3",
          nav: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list_item, {
              class: "text-start",
              disabled: $setup.rail ? true : false,
              href: "https://enumclawpandc.sharepoint.com/:b:/r/sites/FaCTTeam/Shared%20Documents/General/Homer%20Migrated%20Content/UW%20Workbench/UW%20Workbench%20User%20Guide.pdf?csf=1&web=1&e=wWyGUP",
              "prepend-icon": "mdi-book-open-variant",
              title: "User Guide",
              value: "guide"
            }, null, 8 /* PROPS */, ["disabled"]),
            _createVNode(_component_v_list_item, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.sendDocument())),
              disabled: $setup.rail ? true : false,
              class: "text-start custom-list-item",
              "prepend-icon": "mdi-send",
              title: "Send to OnBase",
              value: "onbase"
            }, null, 8 /* PROPS */, ["disabled"]),
            _createVNode(_component_v_list_item, {
              class: "text-start",
              target: "_blank",
              disabled: $setup.rail ? true : false,
              href: $setup.plrReportUrlPolicy,
              "prepend-icon": "mdi mdi-chart-line",
              title: "Policy Loss Ratio Report",
              value: "report"
            }, null, 8 /* PROPS */, ["disabled", "href"]),
            _createVNode(_component_v_list_item, {
              class: "text-start",
              target: "_blank",
              disabled: $setup.rail ? true : false,
              href: $setup.electronicWorksheetURLValue,
              "prepend-icon": "mdi-file-document",
              title: "Most Recent Electronic Worksheet",
              value: "worksheet"
            }, null, 8 /* PROPS */, ["disabled", "href"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "rail", "color"]),
    _createVNode(_component_v_container, {
      fluid: "",
      class: "pa-3 ma-0 w-100"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_v_img, {
            style: {"height":"80px !important"},
            src: require('../assets/img/moe-city.png'),
            cover: "",
            class: "h-auto rounded-t"
          }, null, 8 /* PROPS */, ["src"]),
          _createVNode(_component_v_row, { class: "align-end justify-end" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                sm: "12",
                md: "6",
                lg: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { class: "justify-center xs-mt-8 pt-sm-8 py-md-8" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "10",
                        sm: "10",
                        md: "8",
                        lg: "8"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            id: "policyInput",
                            text: "text",
                            "hide-details": "true",
                            label: "Policy / renewal quote number and module (FPP123456700)",
                            modelValue: $setup.policyNumberInput,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.policyNumberInput) = $event)),
                            variant: "solo",
                            width: "auto",
                            density: "comfortable",
                            onKeyup: _cache[6] || (_cache[6] = _withKeys(($event: any) => ($setup.fetchPolicy()), ["enter"])),
                            onChange: $setup.updatePolicyInput
                          }, null, 8 /* PROPS */, ["modelValue", "onChange"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_v_col, {
                        cols: "10",
                        sm: "10",
                        md: "2",
                        lg: "2"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_m_btn, {
                            height: "48px",
                            width: "120px",
                            color: "secondary",
                            class: "font-weight-bold",
                            onClick: _cache[7] || (_cache[7] = ($event: any) => ($setup.fetchPolicy())),
                            text: "Import"
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_col, {
                cols: "12",
                sm: "12",
                md: "6",
                lg: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { class: "justify-center xs-mb-8 pb-sm-8 py-md-8" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "10",
                        sm: "10",
                        md: "8"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_text_field, {
                            id: "addressInput",
                            "hide-details": "true",
                            label: "Enter an address or latitude, longitude",
                            variant: "solo",
                            modelValue: $setup.addressInput,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.addressInput) = $event)),
                            onChange: $setup.updateAddressInput,
                            width: "auto",
                            density: "comfortable"
                          }, null, 8 /* PROPS */, ["modelValue", "onChange"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_v_col, {
                        cols: "10",
                        sm: "10",
                        md: "2"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_m_btn, {
                            height: "48px",
                            width: "120px",
                            color: "secondary",
                            class: "font-weight-bold",
                            onClick: _cache[9] || (_cache[9] = ($event: any) => ($setup.addLocation())),
                            text: "Add"
                          })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        ($setup.displayPolicyDetails)
          ? (_openBlock(), _createBlock(_component_v_container, {
              key: 0,
              fluid: "",
              class: "my-10 mx-0 pa-3 text-start elevation-1 rounded"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "bg-primary rounded-t text-lg font-weight-bold text-start mb-3" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => _cache[10] || (_cache[10] = [
                        _createTextVNode("Policy Highlights")
                      ])),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.chips, (chip) => {
                  return (_openBlock(), _createBlock(_component_v_chip, {
                    class: _normalizeClass(["ma-2", chip.type]),
                    size: "large",
                    key: chip.id,
                    color: chip.color,
                    outlined: "",
                    label: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, {
                        start: "",
                        icon: chip.icon
                      }, null, 8 /* PROPS */, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(chip.text), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class", "color"]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        ($setup.lossRatio)
          ? (_openBlock(), _createBlock(_component_v_container, {
              key: 1,
              fluid: "",
              class: "my-10 mx-0 pa-3 elevation-1 rounded policyLossRatios"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "bg-primary rounded-t text-lg font-weight-bold text-start" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => _cache[11] || (_cache[11] = [
                        _createTextVNode("Policy Loss Ratios")
                      ])),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }),
                _createCommentVNode(" First Row: Expiring Term and Three Years by Policy Type "),
                _createVNode(_component_v_row, { class: "text-start" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "2" }, {
                      default: _withCtx(() => [
                        _cache[13] || (_cache[13] = _createElementVNode("h5", null, "Expiring Term by Policy Type", -1 /* HOISTED */)),
                        _createElementVNode("table", _hoisted_2, [
                          _cache[12] || (_cache[12] = _createElementVNode("thead", null, [
                            _createElementVNode("tr", null, [
                              _createElementVNode("th", null, "Policy Type"),
                              _createElementVNode("th", { class: "text-end" }, "Loss Ratio")
                            ])
                          ], -1 /* HOISTED */)),
                          _createElementVNode("tbody", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.lossRatio.expiringTermLossRatios, (term, index) => {
                              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                _createElementVNode("td", null, _toDisplayString(term.lossDescription), 1 /* TEXT */),
                                _createElementVNode("td", _hoisted_3, _toDisplayString($setup.formatRatio(term.lossRatio)), 1 /* TEXT */)
                              ]))
                            }), 128 /* KEYED_FRAGMENT */))
                          ])
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "2" }, {
                      default: _withCtx(() => [
                        _cache[15] || (_cache[15] = _createElementVNode("h5", null, "Three Years by Policy Type", -1 /* HOISTED */)),
                        _createElementVNode("table", null, [
                          _cache[14] || (_cache[14] = _createElementVNode("thead", null, [
                            _createElementVNode("tr", null, [
                              _createElementVNode("th", null, "Policy Type"),
                              _createElementVNode("th", { class: "text-end" }, "Loss Ratio")
                            ])
                          ], -1 /* HOISTED */)),
                          _createElementVNode("tbody", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.lossRatio.threeYearsLossRatios, (term, index) => {
                              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                _createElementVNode("td", null, _toDisplayString(term.lossDescription), 1 /* TEXT */),
                                _createElementVNode("td", _hoisted_4, _toDisplayString($setup.formatRatio(term.lossRatio)), 1 /* TEXT */)
                              ]))
                            }), 128 /* KEYED_FRAGMENT */))
                          ])
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "8" }, {
                      default: _withCtx(() => [
                        _cache[17] || (_cache[17] = _createElementVNode("h5", null, "Three Years Loss Activity", -1 /* HOISTED */)),
                        _createElementVNode("table", null, [
                          _cache[16] || (_cache[16] = _createElementVNode("thead", null, [
                            _createElementVNode("tr", null, [
                              _createElementVNode("th", null, "Claim Number"),
                              _createElementVNode("th", null, "DOL"),
                              _createElementVNode("th", null, "Loss Description"),
                              _createElementVNode("th", { class: "text-end" }, "Total Loss")
                            ])
                          ], -1 /* HOISTED */)),
                          _createElementVNode("tbody", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.lossRatio.claims, (claim, index) => {
                              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                                _createElementVNode("td", null, _toDisplayString(claim.claimNumber), 1 /* TEXT */),
                                _createElementVNode("td", null, _toDisplayString($setup.formatDate(claim.dol)), 1 /* TEXT */),
                                _createElementVNode("td", _hoisted_5, _toDisplayString(claim.lossDescription), 1 /* TEXT */),
                                _createElementVNode("td", _hoisted_6, _toDisplayString($setup.formatCurrency(claim.totalLoss)), 1 /* TEXT */)
                              ]))
                            }), 128 /* KEYED_FRAGMENT */))
                          ])
                        ])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.resultsInput, (location, index) => {
          return (_openBlock(), _createBlock(_component_v_container, {
            fluid: "",
            key: index,
            class: "mt-10 h-auto rounded elevation-1 resultsBox ml-0 mr-0 w-100 pa-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_container, {
                fluid: "",
                class: "ma-0 pa-0 w-100"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, { class: "bg-primary font-weight-bold text-lg ma-0 rounded-t align-center" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "4",
                        class: "text-start"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Address Location: " + _toDisplayString(location.locNum), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */),
                      _createVNode(_component_v_col, {
                        cols: "3",
                        class: "text-start"
                      }, {
                        default: _withCtx(() => [...(_cache[18] || (_cache[18] = [
                          _createTextVNode(" Total Insured Value: ")
                        ]))]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_v_col, { cols: "2" }, {
                        default: _withCtx(() => [...(_cache[19] || (_cache[19] = [
                          _createTextVNode(" Fire Score ")
                        ]))]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_v_col, { cols: "2" }, {
                        default: _withCtx(() => [...(_cache[20] || (_cache[20] = [
                          _createTextVNode(" Hail Score ")
                        ]))]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_v_col, {
                        cols: "1",
                        class: "text-end"
                      }, {
                        default: _withCtx(() => [
                          (location.manuallyAdded)
                            ? (_openBlock(), _createBlock(_component_m_btn, {
                                key: 0,
                                onClick: ($event: any) => ($setup.removeLocation(index)),
                                size: "small",
                                text: "Remove",
                                color: "secondary",
                                class: "font-weight-bold"
                              }, null, 8 /* PROPS */, ["onClick"]))
                            : _createCommentVNode("v-if", true)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */),
                  _createVNode(_component_v_row, { class: "ma-3 d-flex justify-start align-start" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "4",
                        class: "text-start"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(location.address), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */),
                      _createVNode(_component_v_col, { cols: "3" }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(location.insLineTIVs, (insLine, insIndex) => {
                            return (_openBlock(), _createBlock(_component_v_row, {
                              fluid: "",
                              "no-gutters": "",
                              key: insIndex,
                              class: "d-flex ma-0 pa-0 mb-2"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { class: "text-start pa-0 ma-0" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(insLine.insLine) + ": ", 1 /* TEXT */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */),
                                _createVNode(_component_v_col, { class: "text-end pa-0 ma-0" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString($setup.formatCurrency(insLine.tiv)), 1 /* TEXT */)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */)
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1024 /* DYNAMIC_SLOTS */))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */),
                      _createVNode(_component_v_col, { cols: "2" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(location.fireScore), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */),
                      _createVNode(_component_v_col, { cols: "2" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(location.hailScore), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */),
              ($setup.displayError[index] === true)
                ? (_openBlock(), _createBlock(_component_v_alert, {
                    key: 0,
                    closable: "",
                    icon: "mdi mdi-alert",
                    text: "WARNING: Location TIV exceeds $10,000,000 and may require manager referral. Check your LOA and referral history",
                    variant: "tonal",
                    class: "font-weight-bold bg-warningAddress text-start my-5 mx-2"
                  }))
                : _createCommentVNode("v-if", true),
              (location.partialMatch === true)
                ? (_openBlock(), _createBlock(_component_v_alert, {
                    key: 1,
                    closable: "",
                    icon: "mdi mdi-home-alert",
                    text: "WARNING: This address may not be an exact match. Enter latitude, longitude.",
                    variant: "tonal",
                    class: "font-weight-bold bg-warningLabel text-start my-5 mx-2"
                  }))
                : _createCommentVNode("v-if", true),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_GoogleMap, {
                  id: "mapId",
                  class: "mapOcurrance",
                  "api-key": "AIzaSyCeDaVPyjqmNXZBlG2H0Uv3UNc41pmgjes",
                  style: {"width":"100%","height":"400px"},
                  center: location.location,
                  zoom: 19,
                  tilt: 45,
                  "map-type-id": 'hybrid',
                  ref_for: true,
                  ref: (el) => $setup.setMapRef(el as any, index)
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Marker, {
                      options: { position: location.location }
                    }, null, 8 /* PROPS */, ["options"])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["center"])
              ]),
              _createVNode(_component_v_row, { class: "justify-space-between align-center rounded-b bg-primary ma-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "2" }, {
                    default: _withCtx(() => [...(_cache[21] || (_cache[21] = [
                      _createElementVNode("p", { class: "text-center text-lg font-weight-bold m-0" }, "Location notes:", -1 /* HOISTED */)
                    ]))]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, { cols: "10" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_textarea, {
                        clearable: "",
                        "hide-details": "true",
                        "auto-grow": true,
                        class: "mx-2",
                        rows: "1",
                        label: "Comments",
                        variant: "solo",
                        placeholder: "Enter any comments on location"
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 2 /* DYNAMIC */
          }, 1024 /* DYNAMIC_SLOTS */))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    })
  ], 64 /* STABLE_FRAGMENT */))
}