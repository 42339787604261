import { CommercialApiContract } from "@/contracts/CommercialApiContract";
import { AuthenticatedFetch } from "@/services/AuthenticatedFetch";
import { PolicyService } from "@/contracts/PolicyService";
import { RateService } from "@/contracts/RateService";
import { ConfigService } from "@/contracts/ConfigService";
import * as bdModels from "@/models/BulkDeductibleViewModels";
import * as ratingModels from "@/models/RatingModels";
import * as policyModels from "@/models/PolicyModels";
import * as configModels from "@/models/ConfigModels";
import exp from "constants";

//default implementation for the Commercial Api
export class CommercialApi implements PolicyService,
	RateService,
	ConfigService {
	
	apiConfig: CommercialApiContract;
	authFetch: AuthenticatedFetch;

	constructor(apiConfig: CommercialApiContract, authFetch: AuthenticatedFetch) {
		this.apiConfig = apiConfig;
		this.authFetch = authFetch;
	}

	async getBulkDeductibles(policyNumber: string): Promise<bdModels.BulkDeductibleViewModel> {
		return await fetch(`${this.apiConfig.endpoint}/policy/bulkdeductibles?policyNumber=${policyNumber}`)
			.then(response => response.json());
	}

	async updateBulkDeductibles(post: bdModels.UpdateBulkDeductiblePost): Promise<Response> {
		const response = await fetch(`${this.apiConfig.endpoint}/policy/updatedeductibles`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json;'
			},
			body: JSON.stringify(post),
		});

		if (response.ok) {
			return response;
		} else {
			throw new Error(`Error: ${response.status} - ${response.statusText}`);
		}
	}

	async ratePolicy(reateRequest: ratingModels.RateRequest): Promise<Response> {
		const response = await fetch(`${this.apiConfig.endpoint}/rating/rate`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json;'
			},
			body: JSON.stringify(reateRequest)
		});

		if (response.ok) {
			return response;
		} else {
			throw new Error(`Error: ${response.status} - ${response.statusText}`);
		}
	}

	async searchPolicies(searchRequest: policyModels.PolicySearchRequest): Promise<policyModels.PolicySearchResponse> {
		const response = await fetch(`${this.apiConfig.endpoint}/policy/search`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(searchRequest)
		});

		if (response.ok) {
			const result: policyModels.PolicySearchResponse = await response.json();
			return result;
		}
		else {
			throw new Error(`Error: ${response.status} - ${response.statusText}`);
		}
	}

	async getRatingDetails(documentId: string): Promise<ratingModels.GetRateDetailResponse> {
		const response = await fetch(`${this.apiConfig.endpoint}/rating/ratingDetails/${documentId}`, {
			method: 'GET'
		});

		if (response.ok) {
			const result: ratingModels.GetRateDetailResponse = await response.json();
			return result;
		}
		else {
			throw new Error(`Error: ${response.status} - ${response.statusText}`);
		}
	}

	async getCoverageSummary(documentId: string): Promise<ratingModels.CoverageSummaryItem[]> {
		const response = await fetch(`${this.apiConfig.endpoint}/rating/coverageSummary/${documentId}`, {
			method: 'GET'
		});

		if (response.ok) {
			const result: ratingModels.CoverageSummaryItem[] = await response.json();

			return result;
		}
		else {
			throw new Error(`Error: ${response.status} - ${response.statusText}`);
		}
	}

	async getLossRatio(policyNumber: string): Promise<policyModels.LossRatioResponse> {
		const response = await fetch(`${this.apiConfig.endpoint}/workbench/policyloss/${policyNumber}`);

		if (response.ok) {
			const result: policyModels.LossRatioResponse = await response.json();

			return result;
		}
		else {
			throw new Error(`Error: ${response.status} - ${response.statusText}`);
		}
	}

	async getMessageClientConnection(hubName: string): Promise<configModels.GetMessageClientConnectionInfoResponse> {
		
		const response = await this.authFetch.fetch(`${this.apiConfig.endpoint}/config/for/messaging/${hubName}`, {
			method: 'POST'
		});

		if (response.ok) {
			const result: configModels.MessageClientConnectionInfo = await response.json();

			return new configModels.GetMessageClientConnectionInfoResponse({
				connectionInfo: result,
				success: true
			});
		}
		else {
			return new configModels.GetMessageClientConnectionInfoResponse({
				connectionInfo: null,
				message: `Error: ${response.status} - ${response.statusText}`,
				success: false
			});
		}
	}
}
